import React, { useState } from "react";
import { Link } from "react-router-dom";
import useApi, { fetcher, useApis, doFetch } from "storybook-dashboard/utils/fetching";
import { BasicSpinner } from "storybook-dashboard/spinners/basicSpinner";
import DataTable from "react-data-table-component";
import { Tooltip } from "react-tippy";
import Octicon from "react-octicon";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import Im from "immutable";
import TipsSpinner from "storybook-dashboard/spinners/tipsSpinner";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import styled from "styled-components";
import { distance } from "fastest-levenshtein";
import { tableCustomStyles } from "../project/dashboard/utils";

export const SupplierLink = styled.span`
  font-weight: 400;
  color: #007bff;
  text-decoration: none;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const ColumnFilter = ({ title, binary, onChange }) => {
  return (
    <span className="text-muted d-flex flex-row mx-1">
      {binary && title}
      {binary ? (
        <select className="custom-select custom-select-sm ml-1" onChange={onChange}>
          <option>–</option>
          <option>Yes</option>
          <option>No</option>
        </select>
      ) : (
        <input className="form-control form-control-sm" placeholder={`Filter by ${title}`} onChange={onChange} />
      )}
    </span>
  );
};

function TooltipTitle({ children, tooltipText }) {
  return (
    <Tooltip animateFill={false} html={<div className="text-left">{tooltipText}</div>}>
      {children}
    </Tooltip>
  );
}

const getRowData = (item, columns) => {
  return columns.map((i) => i.selector(item));
};

function ExportCSVButton({ data, columns }) {
  if (!data) return null;

  let headers = columns.map((i) => i._name || i.name);
  let csvData = [headers, ...data.map((item) => getRowData(item, columns))];

  return (
    <CSVLink className="float-right btn-link mr-2" filename="csv_data.csv" data={csvData}>
      <Octicon name="cloud-download" /> Export to csv
    </CSVLink>
  );
}

const publicDomains = [
  "yahoo.com",
  "gmail.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "protonmail.com",
  "zoho.com",
  "mail.com",
  "gmx.com",
  "yandex.com",
  "inbox.com",
  "me.com",
  //same domains but with .co.uk below
  "yahoo.co.uk",
  "gmail.co.uk",
  "outlook.co.uk",
  "hotmail.co.uk",
  "aol.co.uk",
  "icloud.co.uk",
  "protonmail.co.uk",
  "zoho.co.uk",
  "mail.co.uk",
  "gmx.co.uk",
  "yandex.co.uk",
  "inbox.co.uk",
  "me.co.uk",
];
const mergeSupplierDataAndReportStatuses = (supplierData, reportStatuses) => {
  // Reduce the two data lists to a map with domain as the key
  // if domains are public domains or not found, return and set the company name as the key. Otherwise return set the company domain as the key

  let supplierDataByDomain = supplierData?.reduce((acc, item) => {
    return !publicDomains.includes(item?.get("company_domain")?.toLowerCase()) &&
      item?.get("company_domain")?.length > 0 // if domains are not public and do exist
      ? acc?.set(item?.get("company_domain")?.toLowerCase(), item) // set the domain as the key
      : acc?.set((item?.get("company_name") || "")?.toLowerCase(), item); // otherwise use the company name
  }, Im.Map());

  let reportStatusByDomain = reportStatuses?.reduce((acc, item) => {
    return !publicDomains.includes(item?.getIn(["source", "company_domain"])?.toLowerCase()) &&
      item?.getIn(["source", "company_domain"])?.length > 0
      ? acc.set(item?.getIn(["source", "company_domain"])?.toLowerCase(), item)
      : acc?.set(item?.get("reporting_package_name")?.toLowerCase() || "", item);
  }, Im.Map());

  // Create a Set of all the unique domain names
  let allDomains = Im.Set(supplierDataByDomain?.keys()).union(Im.Set(reportStatusByDomain?.keys()));

  // Map the list of domains to a list of joined data
  let mergedData = allDomains?.map(
    (domain) =>
      Im.Map(reportStatusByDomain?.get(domain, Im.Map()).merge(supplierDataByDomain?.get(domain, Im.Map())))?.set(
        "_key",
        domain
      ) // Keep a record of the key that was used to merge the lists
  );

  // If we have data from the reportStatuses endpoint table then "Registered", "Reported" and "Apportioned"
  // should be true regardless of what the supplier data says
  // (because the supplierData relies on uploading target supplier list - which may be empty)
  let correctedMergedData = mergedData.map((item) => {
    let key = item?.get("_key");
    if (reportStatusByDomain?.has(key)) {
      return item.merge(
        Im.Map({
          has_reported: "Yes",
          has_registered: "Yes",
          apportioned: item.get("coverage") ? "Yes" : "No", // set apportioned to true if they have coverage (completed and apportioned reports)
        })
      );
    }
    return item.set("coverage", 0).set("apportioned", "No"); //set "coverage" to 0 and "apportioned" if they do not exist
  });

  return correctedMergedData;
};

const getReportColumns = (backProjectId, companyId, trigger) => {
  return [
    {
      name: "Supplier name",
      sortable: true,
      selector: (item) => item.supplier || item.reporting_package_name,
      cell: (item) =>
        item.supplier || item.reporting_package_name ? (
          item?.reporting_package_id ? (
            <Link
              to={`/project/${backProjectId}/wpack/${item?.reporting_package_id?.substring(0, 8)}/evals`}
              className="link"
              target="_blank"
            >
              <SupplierLink>{item.supplier || item.reporting_package_name}</SupplierLink>
            </Link>
          ) : (
            item.supplier || item.reporting_package_name
          )
        ) : null,
      grow: 1,
    },
    {
      name: "Email",
      sortable: true,
      selector: (item) => item.email_address,
      cell: (item) =>
        item.email_address ? (
          <Tooltip name="mail" html={<div>{item.email_address}</div>}>
            <a href={`mailto:${item.email_address}`} className="link" target="_blank">
              <Octicon name="mail" />
            </a>
          </Tooltip>
        ) : null,
      grow: 0,
      style: { paddingLeft: "5px" },
    },
    {
      _name: "Registered",
      name: (
        <TooltipTitle tooltipText={<>A tick indicates the supplier is currently registered on the Carbon Calculator</>}>
          Registered
        </TooltipTitle>
      ),
      sortable: true,
      selector: (item) => item.has_registered,
      cell: (item) =>
        item.has_registered === "Yes" ? (
          <Octicon name="check" style={{ color: "green" }} />
        ) : (
          <Octicon name="x" style={{ color: "red" }} />
        ),
      grow: 0,
      // style: { padding: "0" },
    },
    {
      _name: "Reported",
      name: (
        <TooltipTitle tooltipText={<>A tick indicates the supplier has submitted reports on the Carbon Calculator</>}>
          Reported
        </TooltipTitle>
      ),
      sortable: true,
      selector: (item) => item.has_reported,
      cell: (item) =>
        item.has_reported === "Yes" ? (
          <Octicon name="check" style={{ color: "green" }} />
        ) : (
          <Octicon name="x" style={{ color: "red" }} />
        ),
      grow: 0,
    },
    {
      _name: "Apportioned",
      name: (
        <>
          <TooltipTitle tooltipText={<>A tick indicates the supplier has apportioned to you</>}>
            Apportioned
          </TooltipTitle>
        </>
      ),
      sortable: true,
      selector: (item) => item.apportioned,
      cell: (item) =>
        item.apportioned === "Yes" ? (
          <Octicon name="check" style={{ color: "green" }} />
        ) : (
          <Octicon name="x" style={{ color: "red" }} />
        ),
      grow: 0,
    },
    {
      _name: "Target",
      name: (
        <>
          <TooltipTitle tooltipText={<>A tick indicates a Target Supplier</>}>Target</TooltipTitle>
        </>
      ),
      sortable: true,
      selector: (item) => item.target_supplier,
      cell: (item) =>
        item.target_supplier === "Yes" ? (
          <Octicon name="check" style={{ color: "green" }} />
        ) : (
          <Octicon name="x" style={{ color: "red" }} />
        ),
      grow: 0,
    },
    {
      _name: "Report status",
      name: (
        <>
          <TooltipTitle tooltipText={<>A percentage of how complete the supplier's reporting is for the year</>}>
            Report status
          </TooltipTitle>
        </>
      ),
      sortable: true,
      selector: (item) => item.coverage,
      cell: (item) => `${Math.round(item.coverage || 0)}%`,
      grow: 0,
    },
    {
      _name: "",
      name: (
        <>
          Delete
          <TooltipTitle tooltipText={<>Click here to delete all target suppliers</>}>
            <span className="ml-3" onClick={() => deleteTarget(null, companyId, trigger)} style={{ cursor: "pointer" }}>
              <Octicon name="trashcan" />
            </span>
          </TooltipTitle>
        </>
      ),
      selector: (item) => null,
      cell: (item) =>
        item.target_supplier == "Yes" ? (
          <span onClick={() => deleteTarget(item, companyId, trigger)} style={{ cursor: "pointer" }}>
            <Octicon name="trashcan" />
          </span>
        ) : null,
      grow: 0,
    },
  ];
};

function SupplierReport({ companyId, backProjectId }) {
  const [filterText, setFilterText] = useState("");
  const [nameOrEmailFilter, setNameOrEmailFilter] = useState("");
  const [registeredFilter, setRegisteredFilter] = useState("–");
  const [reportedFilter, setReportedFilter] = useState("–");
  const [apportionedFilter, setApportionedFilter] = useState("–");
  const [targetFilter, setTargetFilter] = useState("–");
  const [reportStatusFilter, setReportStatusFilter] = useState("");
  let yearSelectorProps = useYearSelector("All time");
  let { dateRange } = yearSelectorProps;

  let queryParams = new URLSearchParams(dateRange?.filter((v) => v)?.toJS() || {}).toString();

  let { fetches, isLoading, isFetching, trigger } = useApis({
    supplierData: useApi(companyId ? `/api/dashboard/company/${companyId}/supplier/report?${queryParams}` : null),
    reportStatuses: useApi(
      backProjectId ? `/api/dashboard/project/${backProjectId}/reporting_package/status?${queryParams}` : null
    ),
  });

  const supplierData = fetches?.supplierData?.data?.map((supplier) => supplier.set("target_supplier", "Yes")); //these are target suppliers uploaded by client users. We are adding a property to identify them

  const reportStatusData = fetches?.reportStatuses?.data?.map((supplier) => {
    if (typeof supplier.get("coverage") === "number")
      return supplier.set("coverage", supplier?.get("coverage")?.toFixed(2) * 100).set("target_supplier", "No");
    else return supplier?.set("target_supplier", "No").set("coverage", 0);
  });

  let tableData = mergeSupplierDataAndReportStatuses(supplierData, reportStatusData);

  //Set the email addresses from source if necessary
  tableData = tableData.map((i) =>
    i.set("email_address", i.get("email_address") || i.getIn(["source", "email_address"]))
  );

  // Set table data to JS for DataTable component
  tableData = tableData?.toJS();

  tableData = tableData.filter(
    (item) =>
      (nameOrEmailFilter === "" ||
        (item.supplier && item.supplier.toLowerCase().includes(nameOrEmailFilter.toLowerCase())) ||
        (item.reporting_package_name &&
          item.reporting_package_name.toLowerCase().includes(nameOrEmailFilter.toLowerCase())) ||
        (item.email_address && item.email_address.toLowerCase().includes(nameOrEmailFilter.toLowerCase()))) &&
      (registeredFilter === "–" || registeredFilter === item.has_registered) &&
      (reportedFilter === "–" || reportedFilter === item.has_reported) &&
      (apportionedFilter === "–" || apportionedFilter === item.apportioned) &&
      (targetFilter === "–" || targetFilter === item.target_supplier) &&
      (reportStatusFilter === "" || item.coverage.toString().toLowerCase().includes(reportStatusFilter.toLowerCase()))
  );

  if (isLoading() || isFetching()) {
    return <TipsSpinner />;
  }

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  let report_columns = getReportColumns(backProjectId, companyId, trigger);

  return (
    <>
      <SupplierList companyId={companyId} triggerReport={trigger} />
      <div className="d-flex flex-row mt-3">
        <ColumnFilter title="Registered" binary onChange={(e) => setRegisteredFilter(e.target.value)} />
        <ColumnFilter title="Reported" binary onChange={(e) => setReportedFilter(e.target.value)} />
        <ColumnFilter title="Apportioned" binary onChange={(e) => setApportionedFilter(e.target.value)} />
        <ColumnFilter title="Target" binary onChange={(e) => setTargetFilter(e.target.value)} />
      </div>
      <div className="d-flex flex-row justify-content-between my-2 ">
        <div className="d-flex flex-row">
          <ColumnFilter title="Name or Email" onChange={(e) => setNameOrEmailFilter(e.target.value)} />
          <ColumnFilter title="Report Status" onChange={(e) => setReportStatusFilter(e.target.value)} />
        </div>
        <div className="d-flex flex-row align-items-center">
          <ExportCSVButton data={tableData} columns={report_columns} />
          <YearSelector {...yearSelectorProps} includeCustom={false} includeNone={true} extraContainerClass="" />
        </div>
      </div>
      <DataTable
        columns={report_columns}
        data={tableData}
        dense={false}
        customStyles={tableCustomStyles}
        fixedHeader={true}
        pagination={true}
      />
    </>
  );
}

const validateCSV = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: function (results) {
        //console.log("Finished reading csv file:", results.data);
        if (!results.data?.length > 1) {
          reject({ status: "No data in CSV file" });
        }
        let headers = new Set(results.data[0]);
        let requiredHeaders = ["Supplier", "Email address"];
        let missingHeaders = requiredHeaders.filter((i) => !headers.has(i));
        if (!missingHeaders.length) {
          resolve({ status: "success" });
        } else {
          reject({ status: `CSV file missing header(s): ${missingHeaders}` });
        }
      },
      error: () => {
        reject({ status: "Error parsing CSV file" });
      },
    });
  });
};

const uploadFile = (companyId, file, triggerReport) => {
  let formData = new FormData();
  formData.append("file", file);

  fetcher(`/api/dashboard/company/${companyId}/supplier/upload`, "POST", formData).then((response) => {
    // //console.log("GOT POST response", response.payload?.toJS());
    if (triggerReport) {
      //console.log("Triggering report update");
      triggerReport();
    }
  });
};

const deleteTarget = (item, companyId, trigger) => {
  doFetch(`/api/dashboard/company/${companyId}/supplier/upload`, "DELETE", item ? { name: item.supplier } : null).then(
    () => location.reload()
  );
};

const handleUploadClick = (companyId, file, triggerReport) => {
  //console.log("Validating and hopefully uploading file", file);

  validateCSV(file)
    .then((msg) => {
      //console.log("Validation success uploading CSV file", msg);
      uploadFile(companyId, file, triggerReport);
    })
    .catch((msg) => {
      alert(msg.status);
    });
};

function UploadSupplierButton({ companyId, triggerReport }) {
  let [showFileSelect, setShowFileSelect] = useState(false);
  let [file, setFile] = useState(null);

  //console.log("UploadSupplierButton file", file);

  if (showFileSelect) {
    return (
      <div className="input-group mb-3">
        <p className="text-muted">
          <b>Ensure your CSV file includes two columns:</b> the first column should list the supplier names with the
          header 'Supplier', and the second column should include the contact email address for that supplier with the
          header 'Email address'. The system will automatically check if the suppliers are registered on the platform
          and if they have reported within the platform.
        </p>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <label className="custom-file-label" htmlFor="inputGroupFile01">
            {file ? file.name : "Choose file"}
          </label>
        </div>
        <div className="input-group-append">
          <span
            style={{ cursor: "pointer" }}
            className="input-group-text py-0"
            id="inputGroupFileAddon01"
            onClick={() => handleUploadClick(companyId, file, triggerReport)}
          >
            Upload
          </span>
        </div>
      </div>
    );
  }

  return (
    <button className="btn btn-sm btn-primary" type="button" onClick={() => setShowFileSelect(true)}>
      Upload supplier list
    </button>
  );
}

let supplier_columns = [
  {
    name: "Supplier name",
    sortable: true,
    selector: (item) => item.supplier_name,
  },
  {
    name: "Contact email",
    sortable: true,
    selector: (item) => item.email_address,
  },
];

function SupplierList({ companyId, triggerReport }) {
  let { data, isFetching, isLoading } = useApi(`/api/dashboard/company/${companyId}/supplier/upload`);

  if (isLoading) {
    return <BasicSpinner />;
  }

  return (
    <div>
      <UploadSupplierButton companyId={companyId} triggerReport={triggerReport} />
    </div>
  );
}

const TargetSuppliers = ({ companyData, companyId, backProjectId }) => {
  return (
    <>
      <p className="mt-0 mb-2 text-muted">
        Upload a list of suppliers (in CSV format) to check whether they have: registered, reported to{" "}
        {companyData?.get("name")}, or reported on our system at all.
      </p>
      <SupplierReport companyId={companyId} backProjectId={backProjectId} />
    </>
  );
};

const AllSuppliers = ({ backProjectId, companyData, companyId }) => {
  let url = backProjectId ? `/api/dashboard/project/${backProjectId}/reporting_package/status` : null;

  let { data, isLoading } = useApi(url);
  if (isLoading) return <TipsSpinner />;
  console.log(data?.toJS(), "dataHere");
  return (
    <div className="container">
      <h4 className="my-3"> Number of suppliers: {data?.size}</h4>
      <ul>
        {data
          ?.sortBy((report) => report?.get("reporting_package_name"))
          ?.map((report, i) => {
            let coverage = report?.get("coverage")?.toFixed(2) * 100;
            return (
              <Link
                key={i}
                to={`/project/${backProjectId}/wpack/${report?.get("reporting_package_id")?.substring(0, 8)}`}
              >
                <li key={i} className="mb-3 row justify-content-between btn-link">
                  {report?.get("reporting_package_name")}
                </li>
              </Link>
            );
          })}
      </ul>
    </div>
  );
};

export default function CarbonSupplierReport({ projectId, refId, companyId, number }) {
  // let { _companyId } = props.match.params;

  let { data: companyData, isFetching, isLoading } = useApi(`/api/company/${companyId}/`);

  // let backProjectId = companyData
  //   ?.get("projects")
  //   ?.sortBy((i) => distance(i.get("name")?.toLowerCase(), "reporting suppliers"))
  //   ?.first()
  //   ?.get("uid")
  //   ?.substring(0, 8);

  return (
    <div className="shadow col-sm-12 bg-white rounded my-3 p-3">
      {/* <Link to={`/project/${backProjectId}`}>
        <button className="btn btn-sm btn-outline-primary">Back</button>
      </Link> */}
      <h5 className="font-weight-bold">{number ? number : null} Reported suppliers</h5>

      <TargetSuppliers backProjectId={projectId} companyData={companyData} companyId={companyId} />
    </div>
  );
}
